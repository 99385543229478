import { MarkdownHandler } from '@system'
import React from 'react'
import SbEditable from 'storyblok-react'

const Markdown = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <MarkdownHandler variant={blok.variant}>{blok.markdown}</MarkdownHandler>
    </SbEditable>
  )
}

export default Markdown
